import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { copyVizCan } from '../lib/apiUtils';
import FixMetadataMessage from 'common/components/AssetActionBar/components/fix_metadata_message';
import GuidanceSummaryV2PropType from 'common/propTypes/GuidanceSummaryV2PropType';
import AssetActionBar from 'common/components/AssetActionBar';
import { PublishButton }
  from 'common/components/AssetActionBar/components/publication_action/publish_button';
import SubmitForApprovalButton from 'common/components/AssetActionBar/components/submit_for_approval_button';
import { checkIsMetadataValid, assetIsDraft } from 'common/views/helpers';

import SaveButton from './SaveButton';
import SaveNotification from './SaveNotification';
import I18n from 'common/i18n';
import { copyFailed } from '../actions';
import { withGuidanceV2 } from 'common/core/approvals/index_new';
import optionallyLocalizeUrls
  from 'common/site_chrome/app/assets/javascripts/socrata_site_chrome/utils/optionally_localize_urls';

// AssetActionBar customized for VizCan (some extra buttons added).
export const VizCanAssetActionBar = ({
  onCopyFailed,
  view,
  parentView,
  isDirty,
  isEphemeral,
  approvalsGuidance
}) => {

  const { status: checkValidityStatus, data: metadataIsValid } = useQuery(
    `check-metadata-validity-${view?.id || 'ephemeral'}`,
    async () => {
      // metadata validity is only relevant if we're on a draft, skip checking otherwise
      if (!assetIsDraft({ coreView: view, isRevision: false, isDraftStory: false })) {
        return Promise.resolve(true);
      }
      return await checkIsMetadataValid(view.id);
    },
    {enabled: !isEphemeral && !!approvalsGuidance }
  );

  // A2B requires a View to work, but ephemeral VizCans have no view (yet).
  if (isEphemeral) {
    return null;
  }

  const isDraftAndNotPending = approvalsGuidance && !withGuidanceV2(approvalsGuidance).isPending() &&
    assetIsDraft({ coreView: view, isRevision: false, isDraftStory: false });
  let renderPrimaryButton = undefined;

  if (approvalsGuidance) {
    // button will be disabled while pending, and also in case of an error
    const disableUpdateDueToMetadata = checkValidityStatus !== 'success' || !metadataIsValid;

    // EN66455 FOLLOW UP: Verify this is how to determine requires approval
    const requiresApproval = withGuidanceV2(approvalsGuidance).canSubmitUpdatePublishedAssetRequest();

    const getDisabledReason = () => {
      if (isDirty) return I18n.t('shared.components.asset_action_bar.publication_action.update_without_saving_error');
      if (disableUpdateDueToMetadata) return <FixMetadataMessage fetchValidityStatus={checkValidityStatus} fixMetadataLink={`/d/${view.id}/edit_metadata`}/>;
    };
    const disabledReason = getDisabledReason();

    if (requiresApproval) {
      renderPrimaryButton = () => (
        <SubmitForApprovalButton
          view={view}
          approvalsGuidance={approvalsGuidance}
          disabled={isDirty || disableUpdateDueToMetadata}
          disabledReason={disabledReason} />);
    } else if (isDraftAndNotPending) {
      renderPrimaryButton = (props) => (
        <PublishButton
          {...props}
          disabled={isDirty || disableUpdateDueToMetadata}
          disabledReason={disabledReason} />);
    }
  }

  const onCopy = async (name) => {
    try {
      const copiedVizCanUid = await copyVizCan(name, view, parentView);
      window.open(optionallyLocalizeUrls(`/d/${copiedVizCanUid}`), '_blank');
    } catch (err) {
      console.error('Cannot copy VizCan Asset', err);
      onCopyFailed();
    }
  };

  const assetActionBarProps = {
    approvalsGuidance,
    user: socrata.currentUser,
    view,
    onCopy,
    ...(renderPrimaryButton && { renderPrimaryButton })
  };
  return (
    <div>
      <AssetActionBar {...assetActionBarProps}>
        {isDraftAndNotPending && <div id="visualization-canvas-actions">
          <SaveButton />
        </div>}
      </AssetActionBar>
      <SaveNotification />
    </div>
  );
};

VizCanAssetActionBar.propTypes = {
  onCopyFailed: PropTypes.func.isRequired,
  view: PropTypes.object, // May be blank if we're ephemeral.
  parentView: PropTypes.object,
  approvalsGuidance: GuidanceSummaryV2PropType, // May be blank if we're ephemeral.
  isDirty: PropTypes.bool,
  isEphemeral: PropTypes.bool
};

function mapStateToProps(state) {
  const { isEphemeral, isDirty, view, parentView, approvalsGuidance } = state;
  return {
    view,
    parentView,
    isEphemeral,
    isDirty,
    approvalsGuidance
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onCopyFailed: copyFailed
  }, dispatch);
}

const ConnectedVizCanAAB = connect(mapStateToProps, mapDispatchToProps)(VizCanAssetActionBar);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  }
});

const QueryClientWrappedVizCanAAB = (props) => (
  <QueryClientProvider client={queryClient}>
    <ConnectedVizCanAAB {...props}/>
  </QueryClientProvider>
);

export default QueryClientWrappedVizCanAAB;
